class benfit extends HTMLElement {
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = `
            <section class="benfit-container" id="benfit-content">
                <div class= "content">
                    <div class="text-head">
                        <span>Kenapa Kami</span>
                        <h1>Mengapa Harus Kami?</h1>
                    </div>
                </div>
                <div class="grid-card">
                    <div class="grid-item item1">
                        <div class="text-content">
                            <h2>Terapis Terlatih dan Berpengalaman</h2>
                            <p>Setiap terapis yantimassage adalah profesional terlatih yang memiliki pengalaman, memberikan pelayanan terbaik untuk Anda.</p>
                        </div>
                        <div class="img-content">
                            <img src="./images/benfit/terapis-terlatih.webp" alt="terapis terlatih">
                        </div>
                    </div>
                    <div class="grid-item item2">
                        <div class="text-content">
                            <h2>Harga Yang Terjangkau</h2>
                            <p>Dapatkan layanan pijat berkualitas tinggi dengan harga yang terjangkau dan hasil yang maksimal.</p>
                        </div>
                        <div class="img-content-2">
                            <img src="./images/benfit/harga-yang-terjangkau.webp" alt="harga yang terjangkau">
                        </div>
                    </div>
                    <div class="grid-item item3">
                        <div class="text-content">
                            <h2>Pemesanan Mudah dan Cepat</h2>
                            <p>Proses pemesanan yang cepat membuat Anda menjadwalkan sesi pijat hanya dengan satu klik.</p>
                        </div>
                        <div class="img-content-3">
                            <img src="./images/benfit/pemesanan-mudah.webp" alt="pemesanan mudah">
                        </div>
                    </div>
                    <div class="grid-item item4">
                        <div class="text-content">
                            <h2>Beragam Layanan Pijat</h2>
                            <p>Kami menawarkan berbagai pilihan layanan pijat, mulai dari pijat relaksasi hingga terapi, sesuai dengan kebutuhan dan preferensi Anda.</p>
                        </div>
                        <div class="img-content">
                            <img src="./images/benfit/layanan-pijat.webp" alt="layanan pijat">
                        </div>
                    </div>
                </div>
            </section>
        `;
    }
}

customElements.define('benfit-content', benfit);