class listService extends HTMLElement {
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = `
            <section class="service-container" id="service-content">
                <div class="content">
                    <div class="text-head">
                        <span>Layanan Pijat</span>
                        <h1>Pilih layanan pijat yang sesuai dengan kebutuhan anda</h1>
                    </div>
                </div>
                <div class="card-service" id="card-service">
                    <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="full body massage" src="./images/service/full-body-massage.webp">
                        </div>
                        <div class="body-text">
                            <h2>Full Body Massage</h2>
                            <p>Memberikan rasa kenyamanan, meredakan otot tubuh yang tegang, dan meningkatkan kualitas tidur</p>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%0ATreatment%20/%20Durasi:%0ATerapis%20yang%20di%20inginkan%20Pria/wanita:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                    <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="totok wajah" src="./images/service/totok-wajah.webp">
                        </div>
                        <div class="body-text">
                            <h2>Totok Wajah</h2>
                            <p>Dapat membantu merileksasi otot-otot wajah, sehingga wajah menjadi kencang dan sehat</p>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%0ATreatment%20/%20Durasi:%0ATerapis%20yang%20di%20inginkan%20Pria/wanita:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                    <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="pijat refleksi" src="./images/service/pijat-refleksi.webp">
                        </div>
                        <div class="body-text">
                            <h2>Pijat Refleksi (Reflexology)</h2>
                            <p>Pijat yang fokus pada saraf untuk melancarkan peredaran darah, dan menghilangkan rasa lelah</p>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%0ATreatment%20/%20Durasi:%0ATerapis%20yang%20di%20inginkan%20Pria/wanita:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                    <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="Full Body Massage & Totok Wajah" src="./images/service/totok-wajah-&-full-body-massage.webp">
                        </div>
                        <div class="body-text">
                            <h2>Full Body Massage & Totok Wajah</h2>
                            <p>Membantu merileksasi otot-otot wajah, sehingga wajah menjadi lebih kencang dan lebih muda</p>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%0ATreatment%20/%20Durasi:%0ATerapis%20yang%20di%20inginkan%20Pria/wanita:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                    <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="kerokan dan pijat" src="./images/service/kerokan-dan-pijat.webp">
                        </div>
                        <div class="body-text">
                            <h2>Full Body Massage & Kerokan</h2>
                            <p>Membantu menyembuhkan masuk angin, sakit kepala dan pegal-pegal, juga memperbaiki metabolisme tubuh sehingga kembali sehat</p>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%0ATreatment%20/%20Durasi:%0ATerapis%20yang%20di%20inginkan%20Pria/wanita:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                     <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="pijat dan lulur" src="./images/service/pijat-dan-lulur.webp">
                        </div>
                        <div class="body-text">
                            <h2>Full Body Massage & Lulur</h2>
                            <p>Lulur sangat membantu untuk mengangkat sel kulit mati, mencerahkan kulit, & membuat kulit lebih lembut serta meremajakan tubuh.</p>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%0ATreatment%20/%20Durasi:%0ATerapis%20yang%20di%20inginkan%20Pria/wanita:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                </div>
            </section>
    `;
    }
}

customElements.define('list-service', listService);