class hero extends HTMLElement {
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = `
        <div class="hero">
            <div class="text-content">
                <div class="text-experience">
                    <span class="xpericence">Lebih dari 10 tahun pengalaman</span>
                </div>
                <h1>Pijat Panggilan Profesional untuk membuat tubuh anda lebih bugar</h1>
                <p>yantimassage merupakan layanan pijat panggilan untuk membuat tubuh anda kembali bugar dengan layanan reflexology, massage, pijat wajah  untuk daerah jakarta selatan</p>
                <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%0ATreatment%20/%20Durasi:%0ATerapis%20yang%20di%20inginkan%20Pria/wanita:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                    Pesan Sekarang
                </a>
            </div>
        </div>
        `;
    }
}

customElements.define('hero-content', hero);