class contact extends HTMLElement {
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = `
            <section class= "container__contact" id="contact-content">
                <div class= "text__content">
                    <h1>Hubungi yantimassage sekarang dan kami siap untuk melayani anda!</h1>
                    <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20diinginkan%20:%0ATreatment%20/%20Durasi:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                    class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                        Pesan Sekarang
                    </a>
                </div>
            </section>
        `;
    }
}

customElements.define('contact-content', contact);