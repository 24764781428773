class listService extends HTMLElement {
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = `
            <section class="service-container" id="service-content">
                <div class="content">
                    <div class="text-head">
                        <span>Layanan Pijat</span>
                        <h1>Pilih layanan pijat yang sesuai dengan kebutuhan anda</h1>
                    </div>
                </div>
                <div class="card-service" id="card-service">
                    <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="full body massage" src="./images/service/full-body-massage.webp">
                        </div>
                        <div class="body-text">
                            <h2>Full Body Massage</h2>
                            <p>Full Body Massage merileksasi tubuh, meredakan ketegangan, dan melonggarkan otot yang kaku akibat stres atau aktivitas</p>
                            <hr>
                            <div class="price">
                                <h3>90 Menit = 180k<h3>
                                <h3>120 Menit = 230k<h3>
                            </div>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%20Full%20body%20massage%0ATreatment%20/%20Durasi%20:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                    <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="pijat refleksi" src="./images/service/pijat-refleksi.webp">
                        </div>
                        <div class="body-text">
                            <h2>Pijat Refleksi (Reflexology)</h2>
                            <p>Pijat Refleksi fokus pada titik saraf di kaki dan tangan untuk melancarkan peredaran darah, mengurangi stres, dan menghilangkan kelelahan</p>
                            <hr>
                            <div class="price">
                                <h3>90 Menit = 180k<h3>
                                <h3>120 Menit = 230k<h3>
                            </div>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%20Pijat%20Refleksi%0ATreatment%20/%20Durasi%20:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                    <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="Full Body Massage & Totok Wajah" src="./images/service/pijat-bayi.webp">
                        </div>
                        <div class="body-text">
                            <h2>Pijat Bayi</h2>
                            <p>Pijat bayi dapat meningkatkan daya tahan tubuh, sirkulasi darah, dan pernapasan bayi dengan cara yang lembut namun efektif</p>
                            <hr>
                            <div class="price">
                                <h3>90 Menit = 180k<h3>
                                <h3>120 Menit = 230k<h3>
                            </div>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%20Pijat%20Bayi%0ATreatment%20/%20Durasi%20:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                     <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="pijat dan lulur" src="./images/service/pijat lulur.webp">
                        </div>
                        <div class="body-text">
                            <h2>Pijat Lulur (Scrub)</h2>
                            <p>Lulur sangat membantu untuk mengangkat sel kulit mati, mencerahkan kulit, & membuat kulit lebih lembut serta meremajakan tubuh.</p>
                            <hr>
                            <div class="price">
                                <h3>90 Menit = 220K<h3>
                                <h3>120 Menit = 250K<h3>
                            </div>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%20Pijat%20Lulur%0ATreatment%20/%20Durasi%20:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                    <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="Full Body Massage & Totok Wajah" src="./images/service/pijat wajah.webp">
                        </div>
                        <div class="body-text">
                            <h2>Full Body Massage & Totok Wajah</h2>
                            <p>Full Body Massage & Totok Wajah membantu merileksasi otot tubuh dan wajah</p>
                            <hr>
                            <div class="price">
                                <h3>90 Menit = 220K<h3>
                                <h3>120 Menit = 250K<h3>
                            </div>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%20Full%20Body%20Massage%20%26%20Totok%20Wajah%0ATreatment%20/%20Durasi%20:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                    <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="Full Body Massage & Totok Wajah" src="./images/service/full-body-&-kerokan.webp">
                        </div>
                        <div class="body-text">
                            <h2>Full Body Massage & Kerokan</h2>
                            <p>Full Body Massage dan Kerokan efektif untuk merelaksasi otot, mengurangi stres, dan meningkatkan sirkulasi darah</p>
                            <hr>
                            <div class="price">
                                <h3>90 Menit = 220K<h3>
                                <h3>120 Menit = 250K<h3>
                            </div>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%20Full%20Body%20Massage%20%26%20Kerokan%0ATreatment%20/%20Durasi%20:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                    <article class="service-item">
                        <div class="service-img"> 
                            <img class="lazyload" alt="Full Body Massage & Totok Wajah" src="./images/service/Full Body Massage & Refleksi.webp">
                        </div>
                        <div class="body-text">
                            <h2>Full Body Massage & Refleksi</h2>
                            <p>Kombinasi sempurna dari pijatan menyeluruh pada seluruh tubuh dan refleksi yang akan menargetkan titik-titik saraf penting</p>
                            <hr>
                            <div class="price">
                                <h3>90 Menit = 220K<h3>
                                <h3>120 Menit = 250K<h3>
                            </div>
                            <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%20Full%20Body%20Massage%20%26%20Refleksi%0ATreatment%20/%20Durasi%20:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                            class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                               Pesan Sekarang
                            </a>
                        </div>
                    </article>
                </div>
            </section>
    `;
    }
}

customElements.define('list-service', listService);