import 'regenerator-runtime';
import '../styles/main.css';
import '../styles/responsive.css';
import './components/navbar.js';
import './components/footer.js';
import './components/hero.js';
import './components/tentang-kami.js';
import './components/list-service.js';
import './components/benfit.js';
import './components/contact.js';
import App from './views/app.js';

const app = new App({
    button: document.querySelector('#hamburger'),
    drawer: document.querySelector('.nav-list'),
    content: document.querySelector('#main-content'),
    nav: document.querySelector('#navigation>li>a'),
});

window.addEventListener('hashchange', () => {
    app.renderPage();
});

window.addEventListener('DOMContentLoaded', async() => {
    app.renderPage();
});