class footer extends HTMLElement {
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = `
            <footer>
                <div class="footer-container">
                    <div class="item-logo">
                        <div class="logo">
                            <a href="#">
                                <img src="./images/logo/massage.webp" alt= "img-logo" width="175">
                            </a>
                        </div>
                        <p>Hak cipta situs © 2024 - Yanti Massage</p>
                    </div>
                    <div class= "main-content">
                        <div class="item">
                            <h2>Menu Utama</h2>
                            <ul>
                                <li>
                                    <a href="#">Tentang Kami</a>
                                </li>
                                <li>
                                    <a href="#">Layanan</a>
                                </li>
                                <li>
                                    <a href="#">Tentang Kami</a>
                                </li>
                                <li>
                                    <a href="#">Kontak</a>
                                </li>
                            </ul>
                        </div>
                        <div class="item">
                            <h2>Kontak Kami</h2>
                            <ul>
                                <li>
                                    <a href="#">081298340600</a>
                                </li>
                                <li>
                                    <a href="#">aditiaprabowo3@gmail.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        `; // eslint-disable-next-line no-extra-semi
    };
    // eslint-disable-next-line no-extra-semi
};

customElements.define('footer-content', footer);