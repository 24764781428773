class tentangKami extends HTMLElement {
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = `
            <section class="container-about" id="about-section">
                <div class= "col-img">
                    <img src="./images/heros/about.webp" alt="about Image">
                </div>
                <div class= "col-text">
                    <span>Tentang Kami</span>
                    <h1>Layanan pijat panggilan profesional untuk anda</h1>
                    <p>yantimassage menyediakan layanan pijat panggilan wanita profesional di daerah jakarta selatan yang dapat meningkatkan kesehatan dan kebugaran tubuh anda. Dengan terapis berpengalaman 10 tahun dan profesional anda akan mendapatkan pijatan dan layanan yang sesuai dengan keinginan dan kebutuhan anda, sehingga tubuh kembali bugar, lebih sehat, dan fresh. kami menawarkan beragam jenis layanan pijatan seperti full body massage, totok wajah, refleksi dengan harga mulai dari 100K.</p>
                    <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20diinginkan%20:%0ATreatment%20/%20Durasi:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                    class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                        Pesan Sekarang
                    </a>
                </div>
            </section>
        `;
    }
}

customElements.define('tentang-kami', tentangKami);