const Home = {
    async render() {
        return `
            <tentang-kami></tentang-kami>
            <list-service></list-service>
            <benfit-content></benfit-content>
            <contact-content></contact-content>
            <footer-content></footer-content>
        `;
    },

    async afterRender() {
        const sections = document.querySelectorAll('section');
        const navLinks = document.querySelectorAll('.nav-list a');

        function setActiveLink() {
            let current = '';

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.clientHeight;

                if (pageYOffset >= sectionTop - sectionHeight / 3) {
                    current = section.getAttribute('id');
                }
            });

            navLinks.forEach((link) => {
                link.classList.remove('active');
                if (link.getAttribute('href') === `#${current}`) {
                    link.classList.add('active');
                }
            });
        }

        window.addEventListener('scroll', setActiveLink);

        navLinks.forEach((link) => {
            link.addEventListener('click', (e) => {
                e.preventDefault();
                navLinks.forEach((l) => l.classList.remove('active'));
                link.classList.add('active');

                const targetSection = document.querySelector(link.getAttribute('href'));
                targetSection.scrollIntoView({ behavior: 'smooth' });
            });
        });
    },
};

export default Home;