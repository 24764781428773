class navbar extends HTMLElement {
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = `
        <div class= "container-navbar">
            <nav>
                <div class="logo">
                    <a href="#">
                        <img src="./images/logo/massage.webp" width="175" alt="logo">
                    </a>
                </div>
                <button type="button" id="hamburger" aria-label="navigation-menu" tabindex="0">☰</button>
                    <ul class="nav-list" aria-label="Navigation Menu">
                        <li aria-label="tentang">
                            <a class="nav-link" href="#about-section" tabindex="0">Tentang Kami</a>
                        </li>
                        <li aria-label="Layanan">
                            <a class="nav-link" href="#service-content" tabindex="0">Layanan</a>
                        </li>
                        <li aria-label="Kenapa Kami">
                            <a class="nav-link" href="#benfit-content" tabindex="0">Kenapa Kami</a>
                        </li>
                        <li aria-label="Kontak">
                            <a class="nav-link" href="#contact-content" tabindex="0">Kontak</a>
                        </li>
                    </ul>
                <div class= "btn">
                    <a href="https://api.whatsapp.com/send?phone=6281298340600&text=Hallo%20Yanti%20Massage%20saya%20mau%20booking%20Pijat%0ANama%20:%0ATanggal%20dan%20Waktu%20:%0ALayanan%20yang%20di%20inginkan%20:%0ATreatment%20/%20Durasi:%0ATerapis%20yang%20di%20inginkan%20Pria/wanita:%0AAlamat%20Pemesan%20:%0ANo.%20Rumah%20/%20Kamar%20:%0ANote:"
                    class="btn" onclick="gtag('event', 'conversion', {'send_to': 'AW-16627400988/T-i0CLKtqeIZEJyCyPg9'});">
                        Pesan Sekarang
                    </a>
                </div>
            </nav>
        </div>
        `;
    }
}

customElements.define('navbar-content', navbar);